.fullBg {
	height: 100%;
	width: 100%;
	/* background: #004df3; */
	background: var(--secondary-black);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.formContainer {
	position: absolute;
	background: inherit;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.formContainer p {
	text-align: center;
	font-size: 1.5em;
	margin: 0 0 30px;
}

input {
	display: flex;
	height: 50px;
	border-radius: 10px;
	width: 300px;
	text-align: center;
	font-size: 1.5em;
}

form button {
	display: flex;
	justify-content: center;
	padding: 10px 30px;
	border-radius: 10px;
	margin: 20px auto 0;
	color: var(--primary-blue);
	background: var(--primary-black);
	border: 3px solid var(--primary-blue);
	cursor: pointer;
	font-weight: bold;
	text-transform: uppercase;
}
