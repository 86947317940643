nav {
	display: flex;
	justify-content: flex-end;
	background: var(--primary-blue);
	padding: 8px 0;
	margin: 0 0 50px;
	font-size: .8em;
}

.currentUser {
	margin: 0 2%;
}
