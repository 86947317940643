.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Mine */

.buttonRound {
	padding: 20px;
	border-radius: 50%;
	display: block;
	border: none;
	margin-right: 20px;
}

.buttonGreen {
	background: green;
	background-image: radial-gradient(lime, transparent);
	background-size: 5px 5px;
	border-radius: 50%;
	border: dotted 2px lime;

	box-shadow: 0 0 20px #111 inset, 0 0 10px lime;
	animation: 3s green infinite;
}

.buttonRed {
	background: red;
	background-image: radial-gradient(brown, transparent);
	background-size: 5px 5px;
	border-radius: 50%;
	animation: 3s red infinite;
	border: dotted 2px red;
	box-shadow: 0 0 20px #111 inset, 0 0 10px red;
}

@keyframes green {
	20% {
		opacity: .1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes red {
	60% {
		opacity: .1;
	}
	20% {
		opacity: 1;
	}
}

.container {
	margin: 20px auto 0;
}

.center {
	display: flex;
	justify-content: center;
}

.currentUser {
	display: flex;
	align-items: center;
	justify-content: center;
}

.currentUser p {
	text-align: center;
	font-size: 1.5em;
	margin: 0;
}

.timestamp {
	font-size: 14px;
	display: block;
}

.box {
	background-color: var(--primary-black);
	box-shadow: 0px 6px 10px -6px #000000;
	padding: 50px 100px;
	border-radius: 10px;
}

.errorContainer {
	text-align: center;
	padding: 1px 0;
	background: var(--primary-red);
}
