body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--secondary-black);
	color: #fff;
	font-size: 20px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
	--primary-blue: #2196f3;
	--primary-red: #b00020;
	--primary-black: #212121;
	--secondary-black: #303030;
}

a {
	color: var(--primary-blue);
}

h1 {
	display: flex;
	justify-content: center;
}

h2 {
	margin: 0 0 20px;
}
